import 'virtual:svg-icons-register';
import '@/css/main.scss';
import './plugins.js';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import 'masonry-layout';
import {FastClick} from 'fastclick';
import WOW from 'wow.js';
import Mustache from 'mustache';
import Swiper from 'swiper';
import barba from '@barba/core';
import './vendor/modernizr-custom-3.0.0.min.js';
import jQuery from 'jquery';

dayjs.locale('nb');
window["jQuery"] = jQuery;
let $m = jQuery;

const equalHeight = async () => {
    await import('./vendor/matchHeight-min.js');
    $m('.equalHeight').matchHeight();
}

const init = async () => {
    // await import('imagesloaded');

    /* A couple of selections. */
    let $window = $m(window),
        $html = $m(document.documentElement),
        $document = $m(document),
        desktop,
        tablet,
        phone,
        label = '',
        gammel_label,
        window_width,
        template = [],
        submit = false;

    /*=======================================================
                        @function events
    =======================================================*/
    // Automatically cancel unfinished ajax requests
    // when the user navigates elsewhere.

    /**
     * Returns the week number for this date.  dowOffset is the day of week the week
     * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
     * the week returned is the ISO 8601 week number.
     * @param int dowOffset
     * @return int
     */
    Date.prototype.getWeek = function (dowOffset) {
        /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

        dowOffset = typeof (dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
        let newYear = new Date(this.getFullYear(), 0, 1);
        let day = newYear.getDay() - dowOffset; //the day of week the year begins on
        day = (day >= 0 ? day : day + 7);
        let daynum = Math.floor((this.getTime() - newYear.getTime() -
            (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
        let weeknum;
        //if the year starts before the middle of a week
        if (day < 4) {
            weeknum = Math.floor((daynum + day - 1) / 7) + 1;
            if (weeknum > 52) {
                let nYear = new Date(this.getFullYear() + 1, 0, 1);
                let nday = nYear.getDay() - dowOffset;
                nday = nday >= 0 ? nday : nday + 7;
                /*if the next year starts before the middle of
                  the week, it is week #1 of that year*/
                weeknum = nday < 4 ? 1 : 53;
            }
        } else {
            weeknum = Math.floor((daynum + day - 1) / 7);
        }
        return weeknum;
    };

    let xhrPool = [];
    $m(document).ajaxSend(function (e, jqXHR) {
        xhrPool.push(jqXHR);
    });
    $m(document).ajaxComplete(function (e, jqXHR) {
        xhrPool = $m.grep(xhrPool, function (x) {
            return x !== jqXHR;
        });
    });
    const abort = function () {
        $m.each(xhrPool, function (idx, jqXHR) {
            jqXHR.abort();
        });
    };

    let oldbeforeunload = window.onbeforeunload;
    window.onbeforeunload = function () {
        let r = oldbeforeunload ? oldbeforeunload() : undefined;
        if (r === undefined) {
            // only cancel requests if there is no prompt to stay on the page
            // if there is a prompt, it will likely give the requests enough time to finish
            abort();
        }
        return r;
    };

    function detect_size() {
        window_width = $window.width();
        if (window_width < 768) {
            label = 'phone';
            if (gammel_label !== label) {
                $html.addClass('phone').removeClass('desktop tablet');
                desktop = tablet = false;
                phone = true;
            }
        } else if (window_width < 1025) {
            label = 'tablet';
            if (gammel_label !== label) {
                $html.removeClass('phone').addClass('desktop tablet');
                desktop = phone = false;
                tablet = true;
            }
        } else {
            label = 'desktop';
            if (gammel_label !== label) {
                $html.removeClass('phone tablet').addClass('desktop');
                desktop = true;
                tablet = phone = false;
            }
        }
        gammel_label = label;


        if ($m('nav ul').width() > ($m('.inner').width() - ($m('.loader').width() + $m('.right').width()))) {
            $html.addClass('mob-meny');
        } else {
            $html.removeClass('mob-meny');
        }


        //Dette er for å proposjonalt resize alle iframes (spotify/youtube/vimeo)
        $m('main iframe').each(function () {
            let $el = $m(this),
                newWidth = $el.parent().width();

            $el.width(newWidth).height(newWidth * $el.attr('data-aspectRatio'));
        });
    }

    let objArr = [];

    const popup = async () => {
        const {default: css} = await import('@/css/print.scss?inline');
        let w = 600,
            h = 400;
        let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
        let dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
        console.log(css);
        let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        let left = ((width / 2) - (w / 2)) + dualScreenLeft;
        let top = ((height / 2) - (h / 2)) + dualScreenTop;

        let innhold = Mustache.render(template['listeprint'], objArr);
        let mywindow = window.open('', 'Programliste', 'width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
        mywindow.document.write('<html><head><title>Programliste</title>');
        mywindow.document.write(`<style>${css}</style>`);
        mywindow.document.write('<script src="//use.typekit.net/mqi7rbd.js"></script><script>try{Typekit.load();}catch(e){}</script>');
        mywindow.document.write('</head><body ><h1>Programliste</h1>');
        mywindow.document.write(innhold);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10

        setTimeout(function () {
            mywindow.print();
            mywindow.close();
        }, 400);

        return true;
    }

    function frontSlideshowTimer() {
        setTimeout(function () {
            $m('.index .swiper-container .swiper-next').trigger('click');
            frontSlideshowTimer();
        }, 5000);
    }

    let FBid = false,
        FBresponse;

    function statusChangeFacebookCallback(response) {
        //console.log('statusChangeCallback', response);
        FBresponse = response;
        //console.logstatusChangeFacebookCallback(response);
        // The response object is returned with a status field that lets the
        // app know the current login status of the person.
        // Full docs on the response object can be found in the documentation
        // for FB.getLoginStatus().
        if (response.status === 'connected') {
            // Logged into your app and Facebook.
            if (!FBid) {
                FB.api('/me', function (response) {
                    FBid = response;
                    //console.log('Successful login for: ' + response.name);
                    let name = response.name.split(" ");
                    $m('#FBstatus').text('Vis at du let her, ' + name[0] + '!');
                    return FBid;
                });
            } else {
                let name = FBid.name.split(" ");
                $m('#FBstatus').text('Vis at du let her, ' + name[0] + '!');
                return FBid;
            }
            //} else if (response.status === 'not_authorized') {
            // The person is logged into Facebook, but not your app.
            //$m('#FBstatus').text('Legg til post');
        } else {
            // The person is not logged into Facebook, so we're not sure if
            // they are logged into this app or not.
            //$m('#FBstatus').text('Legg til post');
            return false;
        }
    }

    function lastJegVarHerSkjema(FBobj) {
        //console.log('Laster skjema', FBobj);
        if (!FBobj) {
            return;
        }

        $m.ajax({
            type: 'POST',
            url: '/historiske/guestEntry',
            data: {FBID: FBobj.id, FBnavn: FBobj.name, entryID: $m('.FBconnect').data('id')},
            cache: false,
            success: function (data) {
                //console.log(data);
                if (data.length) {
                    $html.append('<div id="jeg-var-her-skjema">' + data + '</div>');
                    $document.on('click', '#jeg-var-her-skjema input[type=button]', function () {
                        $m('#jeg-var-her-skjema').remove();
                    });
                }
            },
            error: function (err, msg) {
                console.log(err, msg);
            }
        });

    }

    function sjekkFormFields($denne) {
        let $field = $denne.closest('.field');
        if ($denne.is('input[type=checkbox]')) {
            if ($denne.is(':checked')) {
                $field.addClass('checked');
            } else {
                $field.removeClass('checked');
            }
        } else {
            if ($denne.val().length === 0) {
                $field.removeClass('has-value');
            } else {
                $field.addClass('has-value');
            }
        }
    }

    function getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(+d);
        return {'y': d.getFullYear(), 'w': d.getWeek(1)};
        /*d.setHours(0, 0, 0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        // Get first day of year
        let yearStart = new Date(d.getFullYear(), 0, 1);
        yearStart.setDate(yearStart.getDate() - yearStart.getDay());
        // Calculate full weeks to nearest Thursday
        let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        return {'y': d.getFullYear(), 'w': weekNo};*/
    }

    let rendered;

    function renderArrangement(resultat, visning) {
        if (resultat.length === 0) {
            $m('#arrangementer').html('<div class="ikke-resultat"><h2>Søket ga ingen treff.</h2></div>');
        } else {
            $m('#print').remove();
            let monthNames = ["jan", "feb", "mars", "apr", "mai", "juni", "juli", "aug", "sep", "okt", "nov", "des"],
                i,
                j,
                r,
                week,
                rCopy;
            // console.log(resultat);
            for (i = 0; i < resultat.length; i++) {
                resultat[i].avlyst = resultat[i].alledatoer.filter(function (a) {
                    return a.billettstatus.value === 'avlyst';
                }).length > 0;
                resultat[i].utsatt = resultat[i].alledatoer.filter(function (a) {
                    return a.billettstatus.value === 'utsatt';
                }).length > 0;
                resultat[i].visBillett = !resultat[i].avlyst && !resultat[i].utsatt && resultat[i].billetturl;
                // console.log(resultat[i]);
            }
            if (visning === 'liste') { // liste
                objArr = [];
                $m('#arrangementer').html('');
                let months = [],
                    now = new Date(),
                    monthsLength = 0;
                for (i = 0; i < resultat.length; i++) {
                    r = resultat[i];
                    for (j = 0; j < r.alledatoer.length; j++) {
                        let dato = r.alledatoer[j].dato;
                        let dateTime = new Date(dato.datetime);
                        if (dateTime < now) {
                            continue;
                        }
                        week = getWeekNumber(dateTime);
                        let month = dateTime.getMonth();
                        if (month < 10) {
                            month = '0' + month;
                        }
                        let mId = parseInt(week.y + '' + month);
                        if (!months[mId]) {
                            months[mId] = {
                                sort: week.y + '' + week.w,
                                events: []
                            };
                            monthsLength++;
                            //console.log(month);
                        }
                        rCopy = $m.extend({}, r);
                        rCopy.dato = dato;
                        rCopy.dato.bigdate.y = now.getFullYear() < week.y ? week.y.toString().substr(2, 2) : null;
                        rCopy.billettstatus = r.alledatoer[j].billettstatus;
                        rCopy.billetturl = r.alledatoer[j].billetturl || null;
                        rCopy.visBillett = rCopy.billetturl || rCopy.avlyst;
                        months[mId].events.push(rCopy);
                    }
                }

                for (let i in months) {
                    let m = months[i];
                    if (!m) {
                        continue;
                    }
                    m.events = m.events.sort(function (a, b) {
                        return (new Date(a.dato.datetime)) > (new Date(b.dato.datetime)) ? 1 : -1;
                    });
                    // console.log(template[index]);
                    rendered = Mustache.render(template['liste'], m.events);
                    $m('#arrangementer').append(rendered);
                    objArr = objArr.concat(m.events);
                }

                if (!$m('#print').length) {
                    $m('#arrangementer').before('<a id="print">Print</a>');
                }

            } else if (visning === 'kalender') { //kalender
                if (!resultat.uker) {
                    let lastWeekLong = 0,
                        naa = new Date(),
                        events = [];

                    resultat.uker = [];

                    for (i = 0; i < resultat.length; i++) {
                        r = resultat[i];
                        for (j = 0; j < r.alledatoer.length; j++) {
                            let tidspunkt = r.alledatoer[j].dato.tidspunkt;
                            let d = new Date(r.alledatoer[j].dato.datetime);
                            week = getWeekNumber(d);

                            if (week.w < 10) {
                                week.w = '0' + week.w;
                            }
                            // if (week.w === 53) {
                            // 	week.w = '01';
                            // 	week.y++;
                            // }
                            let eId = week.y + '' + week.w;
                            // console.log(week, uke, year);
                            if (!events[eId]) {
                                events[eId] = [];
                            }
                            rCopy = $m.extend({}, r);
                            rCopy.dato = {
                                tidspunkt: tidspunkt,
                                bigdate: {
                                    'M': monthNames[d.getMonth()] + '.',
                                    'd': (d.getDate() < 10 ? '0' : '') + d.getDate(),
                                    'y': naa.getFullYear() < week.y ? week.y.toString().substr(2, 2) : null,
                                }
                            };
                            rCopy.billettstatus = r.alledatoer[j].billettstatus;
                            rCopy.billetturl = r.alledatoer[j].billetturl;
                            events[eId].push(rCopy);

                            //let tempWeekLong = week.y + '' + week.w;
                            lastWeekLong = Math.max(lastWeekLong, eId);
                        }
                    }


                    let thisDate = new Date(),
                        thisWeekLong = '',
                        counter = 0;

                    let lastWeekLongString = lastWeekLong.toString().match(/.{1,4}/g),
                        aar = parseInt(lastWeekLongString[0]),
                        dag = parseInt(lastWeekLongString[1]) * 7,
                        uker = Math.round((new Date(aar, 0, dag) - thisDate) / 604800000);

                    //console.log(thisDate.getFullYear());
                    thisDate.setDate(thisDate.getDate() + 1 - (thisDate.getDay() || 7));

                    while (counter < uker) {
                        let mon = new Date(+thisDate),
                            sun = new Date(+thisDate),
                            thisWeek = getWeekNumber(thisDate);

                        sun.setDate(sun.getDate() + 6);
                        sun.setHours(23, 59, 59, 0);
                        // console.log(sun);
                        if (thisWeek.w < 10) {
                            thisWeek.w = '0' + thisWeek.w;
                        }
                        // if (thisWeek.w === 53) {
                        // 	thisWeek.w = '01';
                        // 	thisWeek.y++;
                        // }

                        let eId = thisWeek.y + '' + thisWeek.w;

                        let days = [];
                        if (events[eId]) {
                            for (i = 0; i < events[eId].length; i++) {
                                let ev = events[eId][i];
                                for (j = 0; j < ev.alledatoer.length; j++) {
                                    let date = new Date(ev.alledatoer[j].dato.datetime);
                                    let dag = date.getDay() == 0 ? 7 : date.getDay();
                                    if (date.getTime() > mon.getTime() && date.getTime() < sun.getTime() && !days[dag]) {
                                        days[dag] = dag;
                                    }
                                }
                            }
                        }
                        //console.log(naa.getFullYear(), thisWeek.y);
                        thisWeekLong = thisWeek.y + '' + thisWeek.w;
                        resultat.uker.push({
                            'num': thisWeek.w,
                            'year': naa.getFullYear() < thisWeek.y ? thisWeek.y : null,
                            'sort': eId,
                            'range': mon.getDate() + '.' + monthNames[mon.getMonth()] + ' - ' + sun.getDate() + '.' + monthNames[sun.getMonth()],
                            'count': events[eId] ? events[eId].length : 0,
                            'events': events[eId],
                            'days': days
                        });

                        thisDate.setDate(thisDate.getDate() + 7);
                        counter++;
                    }

                    resultat.uker = resultat.uker.sort(function (a, b) {
                        return (a.sort - 0) > (b.sort - 0) ? 1 : -1;
                    });
                }
                // console.log(resultat.uker);
                rendered = Mustache.render(template['kalender'], resultat);
                $m('#arrangementer').html(rendered);
            } else if (visning === 'bilder') {
                let limit = typeof $m('#arrangementer').data('limit') !== 'undefined' ? $m('#arrangementer').data('limit') : null;
                let bilderes = $m.extend([], resultat);
                if (limit) {
                    bilderes = bilderes.slice(0, limit);
                }
                // console.log(template[index]);
                rendered = Mustache.render(template['bilder'], bilderes);
                $m('#arrangementer').html(rendered);

                $m('#arrangementer > *:eq(4)').after($m('#gavekort').html());
                $m('#arrangementer > *:eq(2)').after($m('#nyhetsbrev').html());
                //$m('#gavekort,#nyhetsbrev').remove();
            }
        }
    }

    let resultater = [],
        laster_arr = false;

    function lastArrangement(pjax) {
        let kategori = $m('#kategori').val() || '',
            sal = $m('#sal').val() || '',
            visning = $m('.visning a.current').attr('href') || 'bilder',
            fastSerie = window.fastSerie || '',
            filter = kategori + '-' + sal + '-' + fastSerie;

        pjax = typeof pjax !== 'undefined' ? pjax : false;

        if (visning === 'bilder') {
            $m('#se_program').stop().slideDown();
        } else {
            $m('#se_program').stop().slideUp();
        }
        // console.log(fastSerie, filter);
        $m('#arrangementer').html('<section style="width: 100%; text-align: center; margin: 100px 0 138px;"><img src="/dist/php/loading.php?color=#000"></section>');

        // console.log(kategori, sal, visning);
        if (kategori !== 'all' || sal !== '' || visning !== 'bilder') {
            window.location.hash = "sal=" + sal + "&kategori=" + kategori + "&visning=" + visning;
        } else if (window.location.hash) {
            window.location.hash = "";
        }
        // console.log(typeof (resultater[filter]));
        if (typeof (resultater[filter]) !== 'undefined' && !pjax) {
            console.log('render');
            renderArrangement(resultater[filter], visning);
        } else {
            console.log('henting');
            laster_arr = true;
            let data = {
                "kategori": kategori,
                "sal": sal,
                "serie": fastSerie
            };
            // "/program/json"

            $m.getJSON("/api/program", data, function (resultat) {
                resultat.forEach(function (item) {
                    let datoer = item.alledatoer;
                    let forste = datoer[0];
                    let siste = datoer[datoer.length - 1];
                    let vanlig = null;
                    let forsteDay = dayjs(forste.start);
                    let sisteDay = dayjs(siste.start);
                    let aar = dayjs(siste.start || forste.start);

                    if (forsteDay.isSame(sisteDay, 'day')) {
                        vanlig = forsteDay.format('DD. MMMM');
                    } else {
                        vanlig = forsteDay.format('DD. MMM') + " - " + sisteDay.format('DD. MMM');
                    }
                    if (!dayjs().isSame(aar, 'year')) {
                        vanlig += ' ' + aar.format('YYYY');
                    }

                    item.dato = {
                        "vanlig": vanlig,
                        "datetime": forste.start,
                        "bigdate": {
                            "d": forsteDay.format('D'),
                            "M": forsteDay.format('MMM'),
                        }
                    };
                    datoer.forEach(function (item) {
                        let start = item.start;
                        let slutt = item.slutt;
                        let startDay = dayjs(start);
                        let tidspunkt = startDay.format('HH:mm');
                        if (slutt) {
                            tidspunkt += " - " + dayjs(slutt).format('HH:mm');
                        }
                        item.dato = {
                            "tidspunkt": tidspunkt,
                            "datetime": start,
                            "bigdate": {
                                "d": startDay.format('D'),
                                "M": startDay.format('MMM'),
                            }
                        };
                    });
                });
                renderArrangement(resultat, visning);
                resultater[filter] = resultat;
                laster_arr = false;
            });
        }
        window.fastSerie = null;
    }


    function felles() {

        $m('.lazyload:not(".imgLoading, iframe")').parent().addClass('imgLoading');

        $m('main .text a:not(".historisk-lenke"), main .innholdstekst a').addClass('underline');

        // if (typeof bodyclass !== 'undefined') {
        // 	console.log('bodyclass', bodyclass);
        // 	$m('body').removeClass().addClass(bodyclass);
        // }

        if ($m('replacenav').length) {
            $m('nav').removeClass('vis').html($m('replacenav').html());
            $m('replacenav').remove();
        }

        let nyhetsbrevContent = $m('#nyhetsbrev').html();
        $m('.module-nyhetsbrev').each(function () {
            if ($m(this).hasClass('hoyre')) {
                $m(this).append(nyhetsbrevContent);
            } else {
                $m(this).prepend(nyhetsbrevContent);
            }

            $m('.margin-col-bottom', this).removeClass('margin-col-bottom');
        });

        $m('main iframe').each(function () {
            $m(this)
                // jQuery .data does not work on object/embed elements
                .attr('data-aspectRatio', $m(this).attr('height') / $m(this).attr('width'))
                .removeAttr('height')
                .removeAttr('width');

            if ($m(this).parent().hasClass('video')) {
                $m(this).parent().css({paddingTop: ($m(this).attr('data-aspectRatio') * 100) + '%'});
            }
        });

        detect_size();

        if ($m('.program.popular').length) {
            $m.get('/api/populaer', function (data) {
                let populaer = data.filter(function (item) {
                    return item.id !== removeId;
                }).slice(0, 4);
                let rendered = Mustache.render(template['bilder'], populaer);
                $m('.program.popular').html(rendered);
                $m('.program.popular .lazyload:not(".imgLoading, iframe")').parent().addClass('imgLoading');
            });
        }

        if ($m('.insertSkjema').length) {
            $m('.insertSkjema').each(function () {
                let $denne = $m(this);
                $m.get('/_skjema/' + $denne.data('id'), function (data) {
                    $denne.html(data).removeClass('imgLoading');
                    equalHeight();
                });
            });

        }

        if ($m('#realtime').length && $html.hasClass('webaudio')) {
            $m.get('/realtime', function (data) {
                $m('#realtime').html(data);
            });
        }

        if ($m('.jeg-var-her').length) {
            let tid = 0;

            if (typeof (FB) !== 'function') {
                tid = 500;
            }

            setTimeout(function () {
                FB.getLoginStatus(function (response) {
                    statusChangeFacebookCallback(response);
                });
            }, tid);

            $m('.comment-wrapper').masonry({
                itemSelector: '.comment',
                transitionDuration: 0,
                columnWidth: 300,
                isFitWidth: true,
                gutter: 38
            });
            setTimeout(function () {
                $m('.comment-wrapper').masonry('layout');
            }, 500);
        }

        $m('.dates .day').each(function () {
            if ($m('time', $m(this)).length === 1) {
                $m(this).find('.bigdate').addClass('one');
            }
        });

        $m('.swiper-container').each(function (i) {
            let $this = $m(this);
            if ($this.hasClass('no-slide')) {
                $m('.swiper-change .changer', $this).html($m('.info_bunn', $this).html()).animate({
                    top: 0 + '%',
                    opacity: 1
                });
                return;
            }
            $this.addClass('swiper-' + i);
            new Swiper('.swiper-' + i, {
                loop: true,
                pagination: '.swiper-' + i + ' .swiper-pagination',
                paginationClickable: true,
                keyboardControl: $this.hasClass('keyboardControl'),
                nextButton: '.swiper-' + i + ' .swiper-next',
                prevButton: '.swiper-' + i + ' .swiper-prev',
                onSlideChangeStart: function (swiper) {
                    $m('.swiper-overlay', $m(swiper.container)).removeClass('vis');
                },
                onSlideChangeEnd: function (swiper) {
                    let overlay = $m('.swiper-overlay', $m(swiper.container)),
                        change = $m('.swiper-change .changer', $m(swiper.container)),
                        info = $m(swiper.slides[swiper.activeIndex]).find('.info_bunn');

                    if (window_width > 600) {
                        change.stop().animate({top: -10 + '%', opacity: 0}, function () {
                            $m(this).css({top: 10 + '%'}).html(info.html()).animate({top: 0 + '%', opacity: 1});
                        });
                    }

                    overlay.removeClass('hvit');
                    if ($m(swiper.slides[swiper.activeIndex]).hasClass('overlegg')) {
                        overlay.addClass('vis');
                        if ($m(swiper.slides[swiper.activeIndex]).hasClass('hvit')) {
                            overlay.addClass('hvit');
                        } else {
                            overlay.removeClass('hvit');
                        }
                    }

                    if (info.find('.info.ingen-info').length) {
                        change.parent().addClass('ingen-info');
                    } else {
                        change.parent().removeClass('ingen-info');
                    }
                }
            });
        });

        equalHeight();

        if ($m('#arrangementer').length) {
            lastArrangement(true);
        }

        $m.getJSON('/actions/seomatic/meta-container/meta-title-container/?uri=' + window.location.pathname, null, function (data) {
            $m('title').remove();
            $m('head').append(data.MetaTitleContainer.toString());
        });

        let data = {
            params: {
                fixed: true
            },
            uri: window.location.pathname
        };

        $m.ajax({
            type: 'POST',
            url: '/actions/admin-bar/bar',
            cache: false,
            data: JSON.stringify(data),
            dataType: 'json',
            success: function (data) {
                if (data.response === 'success' && typeof adminBarInit === "function") {
                    // add Admin Bar to the bottom of the <body> element
                    $m('#adminbar').remove();
                    $m('body').append(data.content);

                    $m('#adminbar a').each(function () {
                        $m(this).addClass('ikke-pjax');
                    });
                    // fire init function that gets loaded into the template
                    // via the {{ getAdminBarAssets({ uri: craft.app.request.url }) }} Twig tag
                    if (typeof window.adminBarInit === "function") {
                        window.adminBarInit();
                    }
                }
            },
            error: function (err) {
                console.log("Error");
                console.log(err);
            }
        });
    }

    function stringToObj(string) {
        let obj = {};
        let stringArray = string.split(';');
        for (let i = 0; i < stringArray.length; i++) {
            let kvp = stringArray[i].split('=');
            if (kvp[1]) {
                obj[kvp[0].trim()] = eval(kvp[1]);
            }
        }
        return obj;
    }

    console.log('barba init');
    barba.init({
        timeout: 5000,
        prefetchIgnore: true,
        prevent: function (event) {
            let el = $m(event.el);
            return el.hasClass('ikke-pjax') || el.hasClass('ikke_pjax');
        }
    });
    barba.hooks.beforeLeave(function () {
        // console.log('beforeLeave');
        $m('body').addClass('loading');
        $m('header nav.vis').removeClass('vis');
    });

    barba.hooks.leave(function () {
        // console.log('leave');
        $m('#jeg-var-her-skjema').remove();
    });

    barba.hooks.beforeEnter(function () {
        // console.log('beforeEnter');
        $m('body').removeClass('loading');
        $m('header .vis').removeClass('vis');
    });
    barba.hooks.after(function (data) {
        // console.log('after', data);
        $m('#sok input.sok').blur();
        let html = new DOMParser().parseFromString(data.next.container.innerHTML, "text/html");
        let script = html.getElementsByTagName('script')[0].innerHTML;
        let classes = stringToObj(script);
        if (classes.bodyclass) {
            $m('body').removeClass().addClass(classes.bodyclass);
        }
        $m('main script').each((i, elm) => {
            console.log(elm);
            eval(elm.innerHTML);
        })
        felles();
        window.scrollTo({top: 0, behavior: 'smooth'});
    });


    /*=======================================================
                          @init siden
    =======================================================*/

    if ($m('.no-smil').length) {
        $m('.loader animate').each(function () {
            let $this = $m(this),
                vals = $this.attr('values');

            $this.parent().attr('d', vals.split(';')[1]);
        });
    }

    if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
        $m(".windows").remove();
    } else {
        $m(".mac").remove();
    }

    if (window.location.hash) {
        // Sjekk hash for visninger på lasting
        let hashes = location.hash.substr(1);

        let result = {};
        hashes.split('&').forEach(function (item) {
            result[item.split('=')[0]] = decodeURIComponent(item.split('=')[1]);
        });
        console.log(result);

        if (result.sal) {
            if ($m("#sal option[value=" + result.sal + "]").length) {
                $m("#sal").val(result.sal);
            }
        }

        if (result.kategori) {
            if ($m("#kategori option[value=" + result.kategori + "]").length) {
                $m("#kategori").val(result.kategori);
            }
        }

        if (result.visning) {
            let visning = $m('.visning a[href="' + result.visning + '"]');
            if (visning.length) {
                $m('.visning a.current').removeClass('current');
                visning.addClass('current');
            }

        }
    }

    $m('script[type="text/template"]').each(function () {
        // console.log(template.id);
        let id = this.id.replace("mst-", "");
        template[id] = this.innerHTML;
        Mustache.parse(template[id]);
        this.remove();
    });

    /*let mstTemplates = ['bilder', 'liste', 'kalender'];

    for (let i = 0; i < mstTemplates.length; i++) {
        console.log(mstTemplates[i], i);
        getTemplate(mstTemplates[i], i);
    }*/

    FastClick.attach(document.body);
    new WOW().init();
    felles();

    let resizeTO;
    window.onresize = function () {
        clearTimeout(resizeTO);
        resizeTO = setTimeout(detect_size, 100);
    };

    if ($m('.index .swiper-container').length) {
        frontSlideshowTimer();
    }

    /*=======================================================
                          @Click/hover events
    =======================================================*/

    $document.on('submit', '#sok', function (e) {
        e.preventDefault();
        let q = $m('input.sok', $m(this)).val(),
            sok = $m(this).attr('action') + '/' + q + '/';
        if (q.length === 0) {
            return;
        } else if (q.length <= 2) {
            window.alert('Må være minimum tre tegn!');
            return;
        }
        barba.go(sok);
        // $m.pjax({url: sok, container: 'main'});
    });

    $document.on('click', '.event_listed, .framhevet_listed, .full_listed, .gavekort_listed, .swiper-container .title, .box, .module-framhevet-artikkel', function (e) {

        // e.preventDefault();
        if ($m(e.target).closest('.ikke-pjax').length) {
            return;
        }
        let $a = null;
        if ($m(this).hasClass('event_listed') || $m(this).hasClass('framhevet_listed')) {
            $a = $m(this).find('a').first();
        } else {
            $a = $m(this).find('a').last();
        }

        let href = $a.attr('href');
        if ($a.hasClass('ikke-pjax')) {
            window.location = href;
        } else {
            barba.go(href);
        }
    });

    $document.on('click', '.FBconnect', function () {
        FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                lastJegVarHerSkjema(statusChangeFacebookCallback(response));
            } else {
                FB.login(function (response) {
                    //console.log(response);
                    lastJegVarHerSkjema(statusChangeFacebookCallback(response));
                });
            }

        });
    });

    $document.on('click', 'aside .expand', function () {
        let $parent = $m(this).parent();
        $parent.toggleClass('vis');
    });

    $document.on('click', '.spotifyButton, .video', function () {
        let $parent = $m(this).parent();
        $parent.toggleClass('vis');
        if (!$parent.hasClass('vis')) {
            $parent.find('iframe').attr('src', '').removeClass('lazyloaded').addClass('lazyload');
        }
    });

    $document.on('click', '.topbar a', function (e) {
        e.preventDefault();
        let $this = $m(this);
        if ($this.hasClass('current') || laster_arr) {
            return;
        }
        if ($this.parents('.filter').length) {
            console.log('abort');
            abort();
        }

        $this.parents('.filter, .visning').find('a.current').removeClass('current');
        $this.addClass('current');

        lastArrangement();
    });

    $document.on('change', '.topbar select', function () {
        lastArrangement();
    });

    $document.on('click', '#print', function (e) {
        e.preventDefault();
        popup();
    });

    $document.on('click', '.phone .topbar > div', function () {
        let hasShow = $m(this).hasClass('show');
        $m(this).parent().find('.show').removeClass('show');
        if (!hasShow) {
            $m(this).addClass('show');
        }
    });

    $document.on('click', 'header .open_meny', function () {
        $m(this).toggleClass('vis');
        $m('nav').toggleClass('vis');
        $m('.fontsize, .fontsize-info, .soke-skjema', $m('header')).removeClass('vis');
    });

    $document.on('click', 'header .search', function () {
        $m(this).toggleClass('vis');
        $m('.soke-skjema').toggleClass('vis');
        $m('.fontsize, .fontsize-info, nav, .open_meny', $m('header')).removeClass('vis');

        if ($m(this).hasClass('vis')) {
            $m('#sok input.sok').focus();
        } else {
            $m('#sok input.sok').blur();
        }
    });

    $document.on('click', 'header .fontsize', function () {
        $m(this).toggleClass('vis');
        $m('.fontsize-info').toggleClass('vis');
        $m('.search, .soke-skjema, nav, .open_meny', $m('header')).removeClass('vis');
    });

    $document.on('change keyup', 'input[type=text], input[type=checkbox], select, textarea', function () {
        sjekkFormFields($m(this));
    });

    $document.on('submit', 'form:not(#sok)', function (ev) {
        let $form = $m(this);
        console.log('submit');
        if ($form.is('#nyhetsbrevform')) {
            return;
        }
        ev.preventDefault();
        if (submit) {
            return;
        }

        // Remove any existing error messages from previous attempts
        $form.find('ul.errors').remove();

        $m('.has-errors, .errors', $form).removeClass('has-errors errors');
        let data = $form.serializeArray();

        submit = true;
        // console.log(data);
        // Send it to the server
        $m.ajax({
            type: 'POST',
            url: $form.attr('action'),
            dataType: 'json',
            data: data,
            encode: true,
            success: function () {
                // All good!
                $form.slideUp();
                $m('.thanks', $form.parent()).slideDown(function () {
                    equalHeight();

                    if (phone) {
                        $m('html, body').animate({scrollTop: ($m(".thanks:eq(0)").offset().top - $m('header').outerHeight() - 8)});
                    }
                    if ($form.closest('#jeg-var-her-skjema').length) {
                        setTimeout(function () {
                            location.reload();
                            // $m.pjax.reload({container: 'main'});
                        }, 1000);
                    }
                });
                submit = false;
            },
            error: function (data, ErrorText, thrownError) {
                console.log("Failed to process request correctly, please try again");
                console.log(data);
                console.log(ErrorText, thrownError);
                //console.log(response);
                // Loop through the errors and append to relevant fields
                /*for (let fieldId in response.errors) {

                    // Create the <ul>
                    let $errors = $m('<ul class="errors" />');

                    // Wrap the error messages in <li> tags
                    let messages = $m.map(response.errors[fieldId], function(message) {
                        return '<li>' + message + '</li>';
                    });

                    // Append the error messages to the ul
                    $errors.append(messages.join(''));
                    //console.log(fieldId+'-'+$id);
                    // Select the input element and append the .errors <ul> after it.
                    let $field = $m('#fields-' + fieldId, $form);
                    $field.parents('.field').addClass('has-errors').find('.input').addClass('errors');
                    $field.parent().after($errors);
                }*/

                if (phone) {
                    $m('html, body').animate({scrollTop: ($m(".has-errors:eq(0)").offset().top - $m('header').outerHeight() - 40)});
                }
                equalHeight();
                submit = false;
            }
        });
    });

    $document.on('click', '.facebook, .twitter, .pinterest, .google', function (e) {
        if (typeof $m(this).attr('href') !== 'undefined') {
            return;
        }
        e.preventDefault();
        let $denne = $m(this),
            $denne_a = $denne;
        if (!$denne.is('a')) {
            $denne_a = $m('a', $denne);
        }
        let url = window.location.href;
        let settings = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,';
        if ($denne.hasClass('facebook')) {
            window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'facebook-share-dialog', settings + 'width=626,height=436');
        } else if ($denne.hasClass('twitter')) {
            window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(url), 'twitter', settings + 'width=835,height=460');
        } else if ($denne.hasClass('pinterest')) {
            if ($denne_a.data("pinterest-media")) {
                let url_old = $denne_a.data("pinterest-media").split('?');
                url = url_old[0];
            } else {
                url = document.location.origin + $m('img.pinterest_img').attr('src');
            }

            window.open('https://pinterest.com/pin/create/button/?url=' + encodeURIComponent(url) + '&media=' + encodeURIComponent(url), 'pinterest-dialog', 'width=750,height=320');
        } else if ($denne.hasClass('google')) {
            window.open('https://plus.google.com/share?url=' + url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
        }
        return false;
    });

    $document.on('click', '.week', function () {
        if ($m(this).hasClass('w-0')) {
            return;
        }
        $m(this).toggleClass('open').siblings().removeClass('open').removeAttr('style');
        let $list = $m(this).next();
        if ($m(this).hasClass('open')) {
            if (!$list.data('height')) {
                let h = 0;
                $list.children().each(function () {
                    h += $m(this).outerHeight(true);
                });
                $list.data('height', h);
            }
            $list.css('height', $list.data('height'));
        } else {
            $list.removeAttr('style');
        }

    });

    document.addEventListener('lazyloaded', function (e) {
        let $denne = $m(e.target),
            $parent = $denne.parent();

        if ($parent.hasClass('img skjult')) {
            $parent.removeClass('skjult');
        }

        $parent.removeClass('imgLoading')
        $parent.closest('.lazyloaded').removeClass('imgLoading');
        $denne.removeClass('imgLoading');
        if (!$parent.is('figure')) {
            equalHeight();
        }

        // console.log('loaded', e.target)
    });
}

$m('html').addClass('doneLoading');
window.addEventListener('load', init);
$m('html').addClass('lastet');
